import { useEffect } from "react";
import React from "react";
import { Helmet } from "react-helmet";

const addNoscriptToBody = () => {
  const noscriptElement = document.createElement('noscript');
  const noscriptContent = document.createTextNode(`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=2189712477923429&ev=PageView&noscript=1" />`);
  noscriptElement.appendChild(noscriptContent);
  document.body.appendChild(noscriptElement);
};

const addNoscriptToBodyGA = () => {
  const noscriptElement = document.createElement('noscript');
  const noscriptContent = document.createTextNode(`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KL7TNTQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
  noscriptElement.appendChild(noscriptContent);
  document.body.appendChild(noscriptElement);
};


export default function MainLayout({ children }) {

  var gtag = process.env.REACT_APP_GTAG_ID;
  useEffect(() => {
    addNoscriptToBody();
    addNoscriptToBodyGA();
    //matamo code
    if (navigator.userAgent !== 'ReactSnap') {
      var _paq = window._paq = window._paq || [];
      _paq.push(["disableCookies"]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function () {
        var u = "//matomo.acorda.digital/";
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '2']);
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
      })();
    }
  }, []);
  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://cdn.cookielaw.org/consent/0191f679-1a3e-7f0b-b5cd-ab137f2e5bf1/OtAutoBlock.js" ></script>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="0191f679-1a3e-7f0b-b5cd-ab137f2e5bf1" ></script>
        <script type="text/javascript">
        {`
          function OptanonWrapper() {
            document.getElementById('onetrust-pc-btn-handler').innerHTML = "Do Not Sell My Personal Information";
            document.getElementById('onetrust-accept-btn-handler').innerHTML = "Accept Cookies";
            const button = document.getElementById('onetrust-accept-btn-handler');
            button.innerHTML = 'Accept Cookies <img src="/assets/images/arrow-right.webp" style="margin-left: 12px;height: 14px;width: 8px;" alt="Icon">';
          }
          if (navigator.userAgent !== 'ReactSnap') {
            if(${window.location.hostname === 'inbrija.com' || window.location.hostname === 'www.inbrija.com'}) {
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KL7TNTQ');
            }
          }
        `}
        </script>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`} ></script>
        <script>
        {`
          if (navigator.userAgent !== 'ReactSnap') {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());  gtag('config', "${gtag}");
          }
          if (navigator.userAgent !== 'ReactSnap') {
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2189712477923429');
            fbq('track', 'PageView');
          }`
        }
        </script>
        {/* <!-- Uptime Real User Monitoring Code --> */}
        <script>
        {`
          if (navigator.userAgent !== 'ReactSnap') {
            (function(w,d,s){w._uptime_rum2 = {};w._uptime_rum2.errors=[];w._uptime_rum2.uuid='TT6M-08D4B1F8DEDD542D';w._uptime_rum2.url='https://rumcollector.uptime.com';s=document.createElement('script');s.async=1;s.src='https://rum.uptime.com/static/rum/compiled/v2/rum.js';d.getElementsByTagName('head')[0].appendChild(s);w.addEventListener('error',function(e){w._uptime_rum2.errors.push({ t: new Date(), err: e })});})(window,document);
          }
        `}
        </script>
        {/* <!-- End Uptime Real User Monitoring Code --> */}
     </Helmet>
      <main>
        {children}
      </main>
    </div>
  )
}