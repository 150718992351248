import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { useLocation } from "react-router-dom";
import { toPng } from 'html-to-image';

export default function PDFResources() {
  const ref = useRef(null);
  const [imageSrc, setImageSrc] = useState('')
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fileddg = urlParams.get('file');
  const urlApi = process.env.REACT_APP_API_URL;
  let X5Y = 'Always';
  let P8R = 'Sometimes';
  let A2S = 'Never';
  let B9T = 'While spending time with family';
  let L3M = 'While out in public (for example, in a restaurant or park)';
  let G7K = 'While at work';
  let N1F = 'On an important day (for example, a wedding or birthday)';
  let E4H = 'While joining in outdoor activities';
  let C6J = `There are many things I won't do anymore`;
  let Q0Z = `I've stopped doing some things but still enjoy most of my favorite activities`;
  let V2W = `I’m worried I will have to stop some activities in the future for this reason`;
  let T5U = `I haven’t stopped any activities`
  // console.log("image", imageHeight)

  // Accessing URL parameters
  const searchParams = new URLSearchParams(location.search);
  const answer1 = eval(searchParams.get('answer1'));
  const answer1Raw = searchParams.get('answer1');
  const answer2 = searchParams.getAll('answer2');
  const answer3 = searchParams.getAll('answer3');
  const date = searchParams.get('date');
  const answer2All = answer2.toString();
  const answer2AllSplit = answer2All.split('-');
  let answer2AllCombined1 = [];
  let answer2AllCombinedRaw = [];
  answer2AllSplit.forEach(function (value) {
    if (value.length > 0) {
      answer2AllCombined1.push(eval(value));
      answer2AllCombinedRaw.push((value));
    }
  })
  let answer2AllCombined = answer2AllCombined1.join(', ');

  // console.log(answer2AllSplit[1])

  const answer3All = answer3.toString();
  const answer3AllSplit = answer3All.split('-');
  let answer3AllCombined1 = [];
  let answer3AllCombinedRaw = [];
  answer3AllSplit.forEach(function (value) {
    if (value.length > 0) {
      answer3AllCombined1.push(eval(value));
      answer3AllCombinedRaw.push(value);
    }
  })
  let answer3AllCombined = answer3AllCombined1.join(', ');
  let imageName = '/assets/ddg_images/' + answer1Raw + '-' + answer2AllCombinedRaw.join('-') + '-' + answer3AllCombinedRaw.join('-') + '.png'
  let pngImageGenerated = imageName;
  console.log(imageName)

  async function ModifyPdf() {
    const url = '/pdfs/doctor-discussion-guide_04072023.pdf'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const Boldurl = 'font/Helvetica-Bold-Condenced.ttf';
    const lightUrl = 'font/Helvetica-Light-Condensed.otf';
    const fontBoldBytes = await fetch(Boldurl).then(res => res.arrayBuffer())
    const fontLightBytes = await fetch(lightUrl).then(res => res.arrayBuffer())
    const pngUrl = pngImageGenerated;
    const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    pdfDoc.registerFontkit(fontkit)
    const customFontBold = await pdfDoc.embedFont(fontBoldBytes)
    const customFontLight = await pdfDoc.embedFont(fontLightBytes)
    const pngImage = await pdfDoc.embedPng(pngImageBytes)
    const pngDims = pngImage.scale(.55)


    const text = 'This is an example of how you can use this guide to start talking with your doctor about symptom return.'
    const pages = pdfDoc.getPages()
    const firstPage = pages[1]
    const secondPage = pages[2]
    const { width, height } = firstPage.getSize()
    const htmlHeight = document.getElementById('ddg_html');
    const divHeight = htmlHeight.offsetHeight;
    let heightp1 = document.getElementById("ddg_para_1");
    let para1_height = heightp1.offsetHeight
    let heightp2 = document.getElementById("ddg_para_2")
    let para2_height = heightp2.offsetHeight
    let heightofImage;
    if (para1_height + para2_height <= 230) {
      heightofImage = 320;
    }
    else if (para1_height + para2_height > 230 && para1_height + para2_height <= 300) {
      heightofImage = 330;
    }
    else if (para1_height + para2_height > 300 && para1_height + para2_height <= 400) {
      heightofImage = 340;
    }

    secondPage.drawText(text, {
      x: 65,
      y: 627,
      size: 11.6,
      font: customFontBold,
      color: rgb(0.24313725490196078, 0.08627450980392157, 0.2784313725490196),
      lineHeight: 18
    })

    secondPage.drawImage(pngImage, {
      x: 65,
      y: heightofImage,
      width: pngDims.width,
      height: pngDims.height,

    })

    // count no of words in answers 2
    // line1 limit 79 words
    let answer2line1Flag = 0;
    let answer2line1Length = 0;
    // line2 limit 98 words
    let answer2line2Flag = 1;
    let answer2line2Length = 0;
    let tmp = [];
    let answer2line1Content = [];

    answer2AllCombined.split(' ').forEach(function (value) {
      if (!answer2line1Flag) {
        if (answer2line1Length <= 79) {

          if (answer2line1Length + value.length <= 79) {
            answer2line1Length = answer2line1Length + value.length;
            answer2line1Content.push(value);
          }
          else {
            answer2line1Flag = 1;
            answer2line1Flag = 0;
          }
        }
      }
      if (!answer2line2Flag) {

      }
    })
    if (answer1 === 'Always') {
      // Answer 1-1
      firstPage.drawCircle({
        x: 73,
        y: 580,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }
    if (answer1 === 'Sometimes') {
      // Answer 1-2
      firstPage.drawCircle({
        x: 73,
        y: 558.3,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    if (answer1 === 'Never') {
      // Answer 1-3
      firstPage.drawCircle({
        x: 191.5,
        y: 580.1,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 3-1
    if (answer2AllCombined1.includes('While spending time with family')) {
      firstPage.drawCircle({
        x: 73.3,
        y: 430.3,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 3-2
    if (answer2AllCombined1.includes('While out in public (for example, in a restaurant or park)')) {
      firstPage.drawCircle({
        x: 73.3,
        y: 405,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 3-3
    if (answer2AllCombined1.includes('While at work')) {
      firstPage.drawCircle({
        x: 73.3,
        y: 369.5,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 3-4
    if (answer2AllCombined1.includes('On an important day (for example, a wedding or birthday)')) {
      firstPage.drawCircle({
        x: 73.3,
        y: 343.2,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 3-5
    if (answer2AllCombined1.includes('While joining in outdoor activities')) {
      firstPage.drawCircle({
        x: 73.3,
        y: 307.1,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 4-1

    if (answer3AllCombined.includes(`There are many things I won't do anymore`)) {
      firstPage.drawCircle({
        x: 320.8,
        y: 430.3,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 4-2
    if (answer3AllCombined.includes(`I’m worried I will have to stop some activities in the future for this reason`)) {
      firstPage.drawCircle({
        x: 433.2,
        y: 429.2,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 4-3
    if (answer3AllCombined.includes(`I've stopped doing some things but still enjoy most of my favorite activities`)) {
      firstPage.drawCircle({
        x: 320.8,
        y: 377,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // Answer 4-4
    if (answer3AllCombined.includes(`I haven’t stopped any activities`)) {
      firstPage.drawCircle({
        x: 433.2,
        y: 362,
        size: 5,
        borderColor: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
        borderWidth: 1.5,
        color: rgb(0.39215686274509803, 0.26666666666666666, 0.4196078431372549),
      })
    }

    // const pdfBytes = await pdfDoc.save()
    // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    // document.getElementById('pdf').src = pdfDataUri;
    const pdfBytes = await pdfDoc.save();

    // Create a Blob object from the binary PDF data
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    // Create a URL for the Blob object
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create a link element
    const downloadLink = document.getElementById('pdf');
    downloadLink.href = pdfUrl;
    downloadLink.download = '/pdfs/doctor-discussion-guide.pdf';
    downloadLink.textContent = '/pdfs/doctor-discussion-guide.pdf';
    document.body.appendChild(downloadLink);

    // Trigger the click event
    downloadLink.click();
    window.close();
    // Remove the link from the DOM after the click event
    document.body.removeChild(downloadLink);

    // Open the PDF in the same tab
    setLoader(false)
  }

  const OffsetFun = () => {
    let ans4Offset = document.getElementById('answer4offset').offsetLeft;
    if (675 - ans4Offset > 200 && 675 - ans4Offset < 400) {
      if (answer3AllCombined.length >= 32 && answer3AllCombined.length <= 50) {
        document.getElementById('answer4span').classList.add('answer4case1')
      }
      if (answer3AllCombined.length >= 50 && answer3AllCombined.length <= 80) {
        document.getElementById('answer4span').classList.add('answer4case8')
      }
      if (answer3AllCombined.length >= 80 && answer3AllCombined.length <= 125) {
        document.getElementById('answer4span').classList.add('answer4case9')
      }
      if (answer3AllCombined.length >= 126) {
        document.getElementById('answer4span').classList.add('answer4case10')
      }
    }
    if (675 - ans4Offset > 100 && 675 - ans4Offset < 200) {
      if (answer3AllCombined.length >= 32 && answer3AllCombined.length <= 50) {
        document.getElementById('answer4span').classList.add('answer4case4')
      }
      else {
        document.getElementById('answer4span').classList.add('answer4case5')
      }
    }
    if (675 - ans4Offset > 300 && 675 - ans4Offset < 600) {
      if (answer3AllCombined.length >= 50) {
        document.getElementById('answer4span').classList.add('answer4case3')
      }
      else {
        document.getElementById('answer4span').classList.add('answer4case7')
      }
    }
    if (675 - ans4Offset < 90) {
      if (answer3AllCombined.length >= 50) {
        document.getElementById('answer4span').classList.add('answer4case6')
        document.getElementById('answer4span').classList.remove('answer4case3')
      }
      else {
        document.getElementById('answer4span').classList.add('answer4case5')
        document.getElementById('answer4span').classList.remove('answer4case3')
      }
    }
    let ansLength = answer3AllCombined.length;
    let ans4 = ans4Offset + ' - ' + ansLength;

    setTimeout(() => {
      toPng(ref.current, { cacheBust: true, quality: '200%', skipAutoScale: true, canvasWidth: "800", pixelRatio: 1 })
        .then((dataUrl) => {
          // const link = document.createElement('a')
          setImageSrc(dataUrl);
          ModifyPdf();
        })
        .catch((err) => {
          console.error(err);
        });
    }, 1000);
  }

  useEffect(() => {
    if (fileddg === '/pdfs/doctor-discussion-guide-customized.pdf') {
      OffsetFun();
      if (ref.current === null) {
        return
      }
    }
    else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const filename = urlParams.get('file');

      let pdfUrl;
      var formdata = '{"source_name":"inbrija_pwp_pdf_download","hostname":"' + window.location.hostname + '","filename":"' + urlParams.get('file') + '","utm_campaign":"' + urlParams.get('utm_campaign') + '","utm_medium":"' + urlParams.get('utm_medium') + '","utm_source":"' + urlParams.get('utm_source') + '","utm_content":"' + urlParams.get('utm_content') + '","date_opened":""}';
      fetch(`${urlApi}/pdfdownload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: formdata
      }).then(data => {
      })
      switch (filename) {
        case 'doctor-discussion-guide.pdf':
          pdfUrl = 'https://www.talkaboutoff.com/doctor-discussion-guide.pdf';
          break;
        case 'patient-information.pdf':
          pdfUrl = '/pdfs/patient-information.pdf';
          break;
        case 'inbrija-patient-info-IFU.pdf':
          pdfUrl = '/pdfs/inbrija-patient-info-IFU.pdf';
          break;
        case 'inbrija-instructions-for-use.pdf':
          pdfUrl = '/pdfs/inbrija-instructions-for-use.pdf';
          break;
        case 'helpful-hints.pdf':
          pdfUrl = '/pdfs/helpful-hints.pdf';
          break;
        case 'patient-brochure.pdf':
          pdfUrl = '/pdfs/patient-brochure.pdf';
          break;
        case 'guia-de-conversacion-con-medicos.pdf':
          pdfUrl = '/pdfs/guia-de-conversacion-con-medicos.pdf';
          break;
        default:
          if (filename.substr(-4) === '.pdf') {
            pdfUrl = '/' + filename;
          } else {
            window.location = '/404.html'
          }
          break;
      }
      if (pdfUrl) {
        var anchor = document.createElement('a');
        anchor.href = pdfUrl + '?v=2';
        anchor.download = pdfUrl;
        anchor.click();
        window.close()
      }
    }

  }, [ref, imageSrc])
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0" />
        <meta name="format-detection" content="telephone=no" />
        <script>{`var _paq = window._paq = window._paq || []; _paq.push(["disableCookies"]), _paq.push(["trackPageView"]), _paq.push(["enableLinkTracking"]), function () { var a = "//matomo.acorda.digital/"; _paq.push(["setTrackerUrl", a + "matomo.php"]), _paq.push(["setSiteId", "2"]); var e = document, p = e.createElement("script"), t = e.getElementsByTagName("script")[0]; p.async = !0, p.src = a + "matomo.js", t.parentNode.insertBefore(p, t) }();`}</script>
        <script src="https://code.jquery.com/jquery-3.6.1.min.js" integrity="sha256-o88AwQnZB+VDvE9tvIXrMQaPlFFSUTR+nldQm1LuPXQ=" crossorigin="anonymous"></script>
      </Helmet>
      {fileddg == "/pdfs/doctor-discussion-guide-customized.pdf" ?
        <div className="ddg_page">
          {loader ?
            <div className="ddg_loader"></div> : ''
          }
          <div style={{ opacity: 0 }}>
            <a className="content" id="pdf"></a>
            {
              <div ref={ref} className="ddg-ques" id="ddg_html" style={{ height: '500px', width: '800px', overflow: 'visible' }}>
                <p className="ddg_para_1" id="ddg_para_1">"I would like to talk about the ways that symptom return has affected me. I am <span className="answer1">{answer1}<span className="answer1label">(YOUR ANSWER TO QUESTION 1)</span></span> surprised by symptom return, and it makes me feel <span className="answer2"><span className="answer2label">(YOUR ANSWER TO QUESTION 2)</span></span>.</p>
                <p className="ddg_para_2" id="ddg_para_2"><span>These symptoms have even returned during events in my life like <span className="answer3">{answer2AllCombined}<span className={answer2AllCombined.length < 35 ? "answer3labellength35" : answer2AllCombined.length > 50 && answer2AllCombined.length < 75 ? "answer3labellength70" : answer2AllCombined.length > 70 ? "answer3labellength120" : "answer3label"}>(YOUR ANSWER TO QUESTION 3)</span></span>.</span> Because of this, <span className="answer4" id="answer4offset">{answer3AllCombined}<span className={"answer4label"} id="answer4span">(YOUR ANSWER TO QUESTION 4)</span></span>. I would like to be able to take part in these events again. I would like to talk about how we can make that happen."</p>
              </div>
            }
            {imageSrc && <img src={imageSrc} alt="Captured Image" className="img-fluid" id="imgdim" />}
          </div>
        </div> : ""
      }
    </>
  )
}