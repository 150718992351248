import React, { useState, useEffect } from "react";
import './footer.css'
import EmailModal from '../pages/EmailModal';
import { Helmet } from "react-helmet";

export default function Footer({ year, code, twitterCode, facebookLink, title, des, partNum }) {
  const [thankModal, setThankModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [resEmail, setResEmail] = useState("");
  const [ResemailError, setResEmailError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [name, setName] = useState("");
  const [NameError, setNameError] = useState("");
  const [anonId, setAnonId] = useState("");
  const url = process.env.REACT_APP_API_URL;

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  useEffect(() => {
    // Function to get cookie value
    const getCookieValue = (cookieName) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(cookieName + '='))
        ?.split('=')[1];

      return cookieValue;
    };

    // Usage example
    const anonID = getCookieValue('Anon_ID'); // Replace 'Anon_ID' with the actual name of your cookie
    setAnonId(anonID)
  }, []);

  const validateForm = (e) => {
    var sendForm = 1;
    var nameRegex = /^[a-zA-Z.\- ]+$/;
    var emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    var name = document.getElementById('name');
    var email = document.getElementById('email');
    if (name) {
      if (!name.value.match(nameRegex)) {
        sendForm = 0;
        name.classList.add('error-input');
        setNameError("*This field is required")
      }
      else {
        name.classList.remove('error-input');
        setNameError(false)
      }
    }

    if (email.value) {
      if (!email.value.match(emailRegex)) {
        sendForm = 0;
        email.classList.add('error-input');
        setEmailError('*You must enter a valid email address')
      }
      else {
        email.classList.remove('error-input');
        setEmailError(false)
      }
    }
    const emails = document.getElementById("emails").value;
    if (typeof emails !== "string") {
      throw new Error(
        "Invalid input. Please provide a string of email addresses."
      );
    }
    var emailList = emails.split(",").map((email) => email.trim());
    for (var i = 0; i < emailList.length; i++) {
      var email = emailList[i];

      if (emails) {
        if (!emailRegex.test(email)) {
          document.getElementById("emails").classList.add('error-input');
          setResEmailError('*You must enter a valid email address')
          break;
        } else {
          document.getElementById("emails").classList.remove('error-input');
          setResEmailError('')
        }
      }
    }
    return sendForm;
  }
  const SubmitFormFinal = (e) => {
    e.preventDefault();
    var status = validateForm();
    var name = document.getElementById('name');
    var email = document.getElementById('email');
    var resEmail = document.getElementById("emails");

    if (status === 1) {
      fetch(`${url}/signup`, {
        method: 'POST',
        body: JSON.stringify({
          "qn_fromname": name.value,
          "email": email.value,
          "qn_toemails": resEmail.value,
          "visitor_id": "00000000-0000-0000-0000-000000000000",
          "source_name": "inbrija_pwp_brand_share",
          "qn_shareurl": "https://www.inbrija.com/"
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((data) => {
        setMailModal(false)
        setThankModal(true);
        setName(null)
        setEmail(null)
        setResEmail(null)
      })
    }
    else {
    }
  }

  let partNumber = '6/2024 US-INB-2400004';
  if(partNum && partNum.length > 0) {
    partNumber = partNum;
  }
  return (
    <>
      <Helmet>
        <script>
          {`window.addEventListener('load', function() {
              if (document.location.hash.includes('#talking-with-doctor')) {
                gtag('event', 'talkingwithdoctor');
              }
              if (document.location.hash.includes('#prescription-services')) {
                gtag('event', 'prescription_services');
              }
            });
            document.addEventListener('click', function(event) {
              if (event.target.closest('a')&&event.target.closest('a').innerText.includes('Instructions for Use')) {
                gtag('event', 'instructions_for_use');
              }
              if (event.target.closest('a')&&event.target.closest('a').innerText.includes('Patient Information Leaflet')) {
                gtag('event', 'patient_information_leaflet');
              }
            });`}
        </script>
      </Helmet>
      <div className="footer-container desktop-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 left-block">
              <ul className="footer-menu">
                <li><a href="/pdfs/patient-information.pdf" rel="noreferrer" target="_blank" id="PID">Patient Information</a></li>
                <li><a href="https://www.merztherapeutics.com/us/privacy-policy/" target="_blank" rel="noreferrer" id="PP_footer">Privacy Policy</a></li>
                <li><a href="https://www.merztherapeutics.com/terms-of-use/" target="_blank" rel="noreferrer" id="terms_footer">Terms of Use</a></li>
                <li><a href="/sitemap" >Site Map</a></li>
                <li><a href="/contact-us" >Contact Us</a></li>
                <li><button onClick={() => { return null; }} id="ot-sdk-btn" className="ot-sdk-show-settings">Do Not Sell My Personal Information</button></li>
                <li><a href="https://www.inbrija.com/cookie-notice">Cookie Notice</a></li>
              </ul>
            </div>
            <div className="col-md-6 right-block">
              <button className="footer-modal-buttons footer-acorda-button" href="">
                <img src="/assets/images/merz-logo-white.png" alt="Merz Therapeutics Logo" className="pointer merz-logo" id="merz_logo_footer" />
              </button>
            </div>
          </div>
          <div className="saperation col-md-12"></div>
          <div className="footer-bottom">
            <div className="footer-bootom-text show_desktop">
              <p>Copyright &copy; 2025 Merz Pharmaceuticals, LLC. All rights reserved. INBRIJA is a registered trademarks of Merz Pharmaceuticals, LLC.</p>
              <p>This site is intended for US residents only.</p>
              <p className="footer-code"> {partNumber} </p>
            </div>
            <div className="footer-bootom-text show_mobile">
              <p>Copyright &copy; 2025 Merz Pharmaceuticals, LLC. All rights reserved. INBRIJA is a registered trademarks of Merz Pharmaceuticals, LLC.</p>
              <p className="footer-code"> {partNumber} </p>
            </div>
          </div>
        </div>

      </div>
      <EmailModal show={mailModal} handleClose={() => setMailModal(false)}>
        <div className="mail-modal">
          <form onSubmit={SubmitFormFinal} id="form-share">
            <div className="modal-heading-left">You are sharing INBRIJA<span className="superscript">®</span>: INBRIJA.com</div>
            <div className="para-dark-font mt-015">Your name</div>
            <input className={"email-input"} placeholder="Your Name" id="name" onChange={() => validateForm()} />
            {NameError && <div className="error-msg">{NameError}</div>}
            <div className="mail-text">Your name will be used to customize the email sent to your recipient(s).</div>
            <div className="para-dark-font mt-015">Your email</div>
            <input className={emailError ? "error-input" : "email-input"} placeholder="Your Email" id="email" onChange={() => validateForm()} value={email} />
            {emailError && <div className="error-msg">{emailError}</div>}
            <div className="mail-text">This will appear as the "from" email address.</div>
            <div className="para-dark-font mt-015">Recipient's email(s)</div>
            <input className={"email-input"} placeholder="Recipient's Email(s)" id="emails" onChange={() => validateForm()} />
            {ResemailError && <div className="error-msg">{ResemailError}</div>}
            <div className="mail-text">To enter multiple email addresses, please separate addresses with commas.</div>
            <div className="center">
              <button className="mt-015 modal-button" type="submit">SHARE</button>
            </div>
          </form>
          <div className="mail-info-text">The information you provide will be used by Acorda Therapeutics, Inc. in accordance with our Privacy Policy and by parties acting on Acorda's behalf to send them information on INBRIJA. View our <a href="https://www.acorda.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>.</div>
        </div>
      </EmailModal>
      <EmailModal show={thankModal} handleClose={() => setThankModal(false)}>
        <div className=" thankyou-modal">
          <h1>thank you!</h1>
          <p>Your email has been sent.</p>
          <button onClick={() => setThankModal(false)}>Close</button>
        </div>
      </EmailModal>
    </>
  )
}