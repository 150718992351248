import React, { useState, useEffect } from "react";
import './header.css'
import { useNavigate, Link } from "react-router-dom";

export default function HeaderMenu({ children }) {

  const [showMenu, setShowMenu] = useState(false);
  const [offPeriod, setOffPeriod] = useState(false);
  const [aboutINB, setAboutINB] = useState(false);
  const [useINB, setUseINB] = useState(false);
  const [resources, setResources] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const [isHow, setIsHow] = useState(false);
  const [savingDrop, setSavingDrop] = useState(false);
  const [tools, setTools] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab' && event.target.id === 'dropdown-off-period') {
        setIsOpen(true);
      }
      if (event.key === 'Tab' && event.target.id === 'dropdown-off-period-close') {
        setIsOpen(false);
      }
      if (event.key === 'Tab' && event.target.id === 'dropdown-about') {
        setIsAbout(true);
      }
      if (event.key === 'Tab' && event.target.id === 'story') {
        setIsAbout(false);
      }
      if (event.key === 'Tab' && event.target.id === 'howto') {
        setIsHow(true);
      }
      if (event.key === 'Tab' && event.target.id === 'howtoclose') {
        setIsHow(false);
      }
      if (event.key === 'Tab' && event.target.id === 'savingopen') {
        setSavingDrop(true)
      }
      if (event.key === 'Tab' && event.target.id === 'savingclose') {
        setSavingDrop(false)
      }
      if (event.key === 'Tab' && event.target.id === 'toolsopen') {
        setTools(true)
      }
      if (event.key === 'Tab' && event.target.id === 'resources_educational_programs') {
        setTools(false)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'dropdown-about') {
        setIsOpen(true)
      }

      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'sign_up_updates') {
        setTools(true)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'resources_educational_programs') {
        setTools(true)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'toolsopen') {
        setTools(false)
        setSavingDrop(true)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'savingclose') {
        setSavingDrop(true)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'savingopen') {
        setSavingDrop(false)
        setIsHow(true)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'howtoclose') {
        setIsHow(true);
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'howto') {
        setIsHow(false);
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'drop-stories') {
        setIsAbout(true);
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'story') {
        setIsAbout(true);
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'dropdown-about') {
        setIsAbout(false);
        setIsOpen(true)
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'dropdown-off-period-close') {
        setIsOpen(true);
      }
      if (event.key === 'Tab' && event.shiftKey && event.target.id === 'dropdown-off-period') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className="header-menu-container">
      <div>
        <div className="header-menu-width" >

          <div className=" text-left">
            <Link to="/" id="inbrija_logo">
              <img src="/assets/images/inbrija-logo.webp" className="logo" alt="Inbrija logo" />
            </Link>
          </div>

          <div className="flex-start menu-section">
            {/* These are pages that should be crawled by react-snap, but not visible to users */}
            <div className="hidden">
              <a href="/sitemap">sitemap</a>
            </div>

            <div className="dropdown">
              <Link className="text-decoration-none" id="dropdown-off-period" to="/what-are-off-periods">
                <div className="menu"> What Are OFF Periods? <img src="/assets/images/dropdown.webp" alt="arrow" /></div>
              </Link>
              <div className="off-period-margin">
                <div className={`dropdown-menu dropdown-content ${isOpen ? 'open' : ''}`}>
                  <li>
                    <Link to="/what-are-off-periods#return-symptoms" >Return of PD Symptoms</Link>
                  </li>
                  <li>
                    <Link to="/what-are-off-periods#experiencing-off" id="dropdown-off-period-close" >Experiencing OFF Periods</Link>
                  </li>
                </div>
              </div>
            </div>

            <div className="dropdown">
              <Link className="text-decoration-none" id="dropdown-about" to="/about-inbrija">
                <div className="menu">About INBRIJA <img src="/assets/images/dropdown.webp" alt="arrow" /></div>
              </Link>
              <div className="about-inbrija-menu-margin">
                <div className={`dropdown-menu dropdown-content ${isAbout ? 'open' : ''}`}>
                  <Link to="/about-inbrija#what-is-inbrija">What Is INBRIJA?</Link>
                  <Link to="/about-inbrija#talking-with-doctor" id="story">Talking With Your Doctor</Link>
                </div>
              </div>
            </div>

            <div className="dropdown">
              <Link className="text-decoration-none" id="howto" to="/how-to-use-inbrija">
                <div className="menu">How to Use INBRIJA <img src="/assets/images/dropdown.webp" alt="arrow" /></div>
              </Link>
              <div className="use-inbrija-margin">
                <div className={`dropdown-menu dropdown-content ${isHow ? 'open' : ''}`}>
                  <Link to="/how-to-use-inbrija#when-to-use">When to Use INBRIJA</Link>
                  <Link to="/how-to-use-inbrija#learn-how-use-inbrija">Learn How to Use INBRIJA</Link>
                  <Link to="/how-to-use-inbrija#nurse-educator-support">Nurse Educator Support</Link>
                  <Link to="/how-to-use-inbrija#helpful-hints" id="howtoclose">Helpful Hints</Link>
                </div>
              </div>
            </div>

            <div className="dropdown">
              <Link className="text-decoration-none" to="/savings-and-support" id="savingopen">
                <div className="menu" onKeyDown={() => setSavingDrop(false)}>Savings & Support <img src="/assets/images/dropdown.webp" alt="arrow" /></div>
              </Link>
              <div className="saving-support-margin">
                <div className={`dropdown-menu dropdown-content ${savingDrop ? 'open' : ''}`}>
                  <Link to="/savings-and-support#savings-programs">Savings Programs</Link>
                  <Link to="/savings-and-support#prescription-services" id="savingclose">Prescription Support Services</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="collapse-menu">
            {showMenu ?
              <div className="close-container" onClick={() => setShowMenu(false)}>
                <div className="left"></div>
                <div className="right"></div>
              </div>
              : <img src="/assets/images/Menu.webp" alt="menu" className="menu-img" onClick={() => setShowMenu(true)} />}
          </div>
        </div>

        {showMenu &&
          <div className="mobile-menu">
            <div className="mobile-saperator mt-4"></div>
            <p onClick={() => setOffPeriod(!offPeriod)}>What Are OFF Periods? {offPeriod ? <img src="/assets/images/drop-close.webp" alt="arrow" className="drop-close" onClick={() => setOffPeriod(false)} /> : <img src="/assets/images/dropdown.webp" alt="arrow" onClick={() => setOffPeriod(true)} />} </p>
            {offPeriod &&
              <div>
                <ul>
                  <li onClick={() => navigate('/what-are-off-periods#return-symptoms')}>Return of PD Symptoms</li>
                  <li onClick={() => navigate('/what-are-off-periods#experiencing-off')} className="mt-015">Experiencing OFF Periods</li>
                </ul>
              </div>}
            <div className="mobile-saperator"></div>
            <p onClick={() => setAboutINB(!aboutINB)}>About INBRIJA  {aboutINB ? <img src="/assets/images/drop-close.webp" alt="arrow" className="drop-close" onClick={() => setAboutINB(false)} /> : <img src="/assets/images/dropdown.webp" alt="arrow" onClick={() => setAboutINB(true)} />}</p>
            {aboutINB &&
              <div>
                <ul>
                  <li onClick={() => navigate('/about-inbrija#what-is-inbrija')}>What Is INBRIJA?</li>
                  <li className="mt-015" onClick={() => navigate('/about-inbrija#talking-with-doctor')}>Talking With Your Doctor</li>
                </ul>
              </div>}
            <div className="mobile-saperator"></div>
            <p onClick={() => setUseINB(!useINB)}>How to Use INBRIJA {useINB ? <img src="/assets/images/drop-close.webp" alt="arrow" className="drop-close" onClick={() => setUseINB(false)} /> : <img src="/assets/images/dropdown.webp" alt="arrow" onClick={() => setUseINB(true)} />}</p>
            {useINB &&
              <div>
                <ul>
                  <li onClick={() => navigate('/how-to-use-inbrija#when-to-use')}>When to Use INBRIJA</li>
                  <li onClick={() => navigate('/how-to-use-inbrija#learn-how-use-inbrija')} className="mt-015">Learn How to Use INBRIJA</li>
                  <li onClick={() => navigate('/how-to-use-inbrija#nurse-educator-support')} className="mt-015">Nurse Educator Support</li>
                  <li onClick={() => navigate('/how-to-use-inbrija#helpful-hints')} className="mt-015">Helpful Hints</li>
                </ul>
              </div>}
            <div className="mobile-saperator"></div>
            <p onClick={() => setSaving(!saving)}>Savings & Support {saving ? <img src="/assets/images/drop-close.webp" alt="arrow" className="drop-close" onClick={() => setSaving(false)} /> : <img src="/assets/images/dropdown.webp" alt="arrow" onClick={() => setSaving(true)} />}</p>
            {saving &&
              <div>
                <ul>
                  <li onClick={() => navigate('/savings-and-support#savings-programs')}>Savings Programs</li>
                  <li onClick={() => navigate('/savings-and-support#prescription-services')} className="mt-015">Prescription Support Services</li>
                </ul>
              </div>}
            <div className="mobile-saperator"></div>
            <div style={{ textAlign: 'left' }}>
              <a href="https://inbrija-hcp.com"><small className="website-links-mobile">US Healthcare Professionals Website</small></a> <br />
            </div>
            <small className="website-links-mobile-noborder">Questions? <a href="tel://1-833-INBRIJA" className="question"><span className="text-decortion">1-833-INBRIJA</span></a></small>
          </div>
        }
      </div>
      {children}
    </div>
  )
}